import React, { ReactElement } from 'react';
import { AdStatus, AdStatusEnum } from '../../api/ads';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CachedIcon from '@material-ui/icons/Cached';
import PolicyIcon from '@material-ui/icons/Policy';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';


type AdIconProps = {
    active: boolean;
    status: AdStatusEnum;
}

export const AdStatusIcon = React.forwardRef((props: AdIconProps, ref: any) => {

    const adStatusMapping: { [key in AdStatusEnum]: ReactElement } = {
        DRAFT: <BorderColorIcon style={{ color: '#666' }}/>,
        IN_WORK: <CachedIcon style={{ color: 'blue' }}/>,
        ON_CHECK: <PolicyIcon style={{ color: 'purple' }}/>,
        READY_TO_PUBLISH: <LibraryAddCheckIcon style={{ color: 'green' }}/>,
        ACTIVE: <CloudDoneIcon style={{ color: 'green' }}/>,
        UNPUBLISHED: <CloudOffIcon style={{ color: '#666' }}/>,
        INSUFFICIENT_FUNDS: <MoneyOffIcon style={{ color: '#d11d1d' }} />
    };

    if (props.status === AdStatus.ACTIVE && !props.active) {
        return (
            <label title="Поле active = false" {...props} ref={ref}>
                <CloudDoneIcon style={{ color: 'gray' }}/>
            </label>
        );
    }

    return (
        <label {...props} ref={ref}>
            {adStatusMapping[props.status]}
        </label>
    );
});
