import React from 'react';

import { IAd, IAdsAnalyticsListRequest } from '../../api/ads';
import Filters from '../../components/Filter';
import { DateField, Select } from '../../components/Form';
import { IKiosk } from '../../api/kiosks';


const today = new Date();
const monthAgo = new Date();

monthAgo.setMonth(monthAgo.getMonth() - 1);

export const defaultFilter: IAdsAnalyticsListRequest = {
    periodFrom: monthAgo.toISOString(),
    periodTo: today.toISOString(),
    kioskId: undefined,
    adId: undefined,
};

interface IProps {
    filter: IAdsAnalyticsListRequest;
    kiosks: IKiosk[];
    ads: IAd[];
    onApply: any;
}

export default function AdAnalyticsFilter({ filter, kiosks, ads, onApply }: IProps) {
    const kiosksList = kiosks.map((k) => ({ value: k.id, label: k.name }));
    const adsList = ads.map((a) => ({ value: a.id, label: a.name }));

    kiosksList.unshift({ value: 0, label: 'Любой' });
    adsList.unshift({ value: 0, label: 'Любая' });

    return (
        <Filters initial={filter} onSubmit={onApply} btnText="Применить">
            <DateField name="periodFrom" label="Период с" max={today}/>
            <DateField name="periodTo" label="Период по" max={today}/>
            <Select name="kioskId" label="Киоск" options={kiosksList} margin="dense" noEmptyOption/>
            <Select name="adId" label="Реклама" options={adsList} margin="dense" noEmptyOption/>
        </Filters>
    );
}
