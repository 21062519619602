import React from 'react';
import moment from 'moment';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

import { IBoard } from '../../api/boards';


interface IProps {
    board: IBoard;
}


export const BoardNetworkInfo = (props: IProps) => {

    const { timestamp, download, upload } = props.board?.networkInfo;

    if (typeof download === 'undefined') {
        return <></>;
    }

    return (
        <>
            <Typography variant="h6" gutterBottom component="div">
                Статистика сети
            </Typography>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">Обновлено</TableCell>
                            <TableCell align="center">
                                {moment(new Date(timestamp).getTime()).calendar()}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">Скорость скачивания (download)</TableCell>
                            <TableCell align="center">{download.mbps} Mbps</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">Скорость загрузки (upload)</TableCell>
                            <TableCell align="center">{upload.mbps} Mbps</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};