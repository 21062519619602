import React from 'react';

import { Column, Line } from '@ant-design/charts';
import { Paper } from '@material-ui/core';


interface IProps {
    data: object[],
    mode?: 'column' | 'line'
}


const AdAnalyticsChart = (props: IProps) => {
    const config = props.mode === 'column' ?
        {
            data: props.data,
            isStack: true,
            xField: 'date',
            yField: 'count',
            seriesField: 'type',
            label: {
                position: 'top',
                layout: [
                    { type: 'interval-adjust-position' },
                    { type: 'interval-hide-overlap' },
                    { type: 'adjust-color' },
                ],
            },
        }
        : {
            data: props.data,
            xField: 'date',
            yField: 'total',
            point: {
                size: 5,
                shape: 'diamond',
            },
        };

    return (
        <Paper style={{ padding: 30 }}>
            {
                props.mode === 'column' ?
                    // @ts-ignore
                    <Column {...config} />
                    // @ts-ignore
                    : <Line {...config} />
            }
        </Paper>
    );
};

export default AdAnalyticsChart;
