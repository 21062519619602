import React from 'react';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';


interface IProps {
    data: [],
    from: string,
    to: string,
}


export class AdAnalyticsReport extends React.Component<IProps> {

    render() {
        const { data, from, to } = this.props;

        const groupData: any = {};

        const getTextDate = (date: Date) => {
            return `${date.getDate()}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`;
        };

        data.forEach((item: any) => {
            let { boards, overall } = groupData[item.adId] || { boards: [], overall: 0 };

            boards.push(item.boardName);
            overall += item.overall;

            groupData[item.adId] = { adName: item.adName, boards, overall };
        });

        return (
            <Paper style={{ padding: 30, paddingTop: 15 }}>
                <Typography variant="overline" display="block" gutterBottom>
                    <span>Отчет по показам рекламы от {getTextDate(new Date())}</span>
                    <span> (период: c {getTextDate(new Date(from))} по {getTextDate(new Date(to))})</span>
                </Typography>

                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Реклама</TableCell>
                                <TableCell>Показы на панелях</TableCell>
                                <TableCell width={200}>Количество показов</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(groupData).map((key: any) => (
                                <TableRow>
                                    <TableCell>{groupData[key].adName}</TableCell>
                                    <TableCell>{groupData[key].boards.join(', ')}</TableCell>
                                    <TableCell>{groupData[key].overall}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }

}