import styled from 'styled-components';


export const FileUploadContainer = styled.section`
  position: relative;
  margin: 15px 15px 0 15px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;

export const FormField = styled.input`
  font-size: 18px;
  display: none;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
`;

export const FileMetaData = styled.div<any>`
  display: ${(props) => (props.isImageFile ? 'none' : 'flex')};
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: black;
  text-shadow: #aaaaaa;
  font-weight: bold;
  background-color: #dde0efcc;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }
`;

export const PreviewList = styled.section`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }

`;

export const CardWrapper = styled.div<any>`  
  &:hover {
    >.MuiPaper-root {
      border-color: #273238 !important;
    }
  }
`;

export const PreviewContainer = styled.section`
  padding: 0.25rem;
  min-width: 120px;
  min-height: 120px;
  height: auto;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-right: 10px;
  position: relative;

  &:hover {
    ${FileMetaData} {
      display: none;
    }
  }

  & > div:first-of-type {
    height: 100%;
    position: relative;
  }
`;

export const FilePreviewContainer = styled.article`
  margin-bottom: 35px;
  padding: 0 15px;

  span {
    font-size: 14px;
    word-break: break-all;
  }
`;

export const RemoveFileIcon = styled.div`
  cursor: pointer;
  position: absolute !important;
  width: 24px;
  height: 24px;
  max-height: 24px;
  background-color: red;
  z-index: 999;
  right: 0;
  top: 0;
  border-radius: 50%;
  transition: all .2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: white;
  margin-top: -6px;
  margin-right: -6px;

  &:hover {
    transform: scale(1.2);
  }
`;

export const Divider = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12);
  }

  span {
    display: block;
    padding: 0 10px;
    background-color: white;
    z-index: 1;
    margin-top: -1px;
  }
`;

export const ImagePreview = styled.img`
  border-radius: 6px;
  width: 100%;
  max-width: 150px;
  height: 100%;
  object-fit: cover;
`;