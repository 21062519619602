import styled from 'styled-components';


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9990;
  background-color: rgba(0, 0, 0, .7);
  width: calc(100% + 50px);
  height: 100%;
  margin-left: -25px;
  flex-direction: column;
  font-weight: 500;
  color: #fff;
`;

export const BackDrop = ({ children }: any) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};